module.exports = global.config = {
    host: "https://api-gateway.sampangkab.go.id/be-sampangkab",
    hostfrontent: 'https://sampangkab.go.id',
    subdomain: "",
    // google api
    googleApiUrl: 'https://www.googleapis.com',
    apiKeyGoogle: "AIzaSyByMEdNIDEolNzad9h1ATajK1NOr0eQ2Z4",
    channelId: "UC8LiteVYZxjKU8FZfe1zJNQ",
    // instagram api
    instagramApiUrl: 'https://graph.instagram.com',
    // anam tokens
    // instagramApiToken: 'IGQWRPdEM3TlVXakx2NDVvSmdKVEVQSGllcDFkRnN3R1I5MFNqdzUwMzYweVhKaFRkTzI2NkpUZAlR2N1NaNDEwZAlZA2VG9oNUFVOVN0M1NvbGE1OW1fdzRTeWVXVUhnYU9vME1lOGZAUTjQtc0dDNWFWdlU2bmFxVTgZD',
    // kominfo tokens
    instagramApiToken: 'IGQWRPdlZAwU0FuQWhtYXpUa2l5ZA1BzWjJRREVILXBSNEFhNC1GSXZALQllDYmlXbzlqMUxzZAm9rS0c3TUdUWjA0MGpkWXVkUTNGaUN3YS16b0JNd193b2ZAaYmlDZAWIyemo4YWp5NURVc1JfLXdGWW9nckRFRVdtRWMZD',
    nodeEnv: 'production'
}; 